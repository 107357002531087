import { useState } from "react"

import './dropdown.css'

const Dropdown = (props) => {


    const [dropdownState, setDropdownState] = useState(false)

    function dropdownToggle(){
        setDropdownState(!dropdownState)
    }

    return(
        <>
            <div className={`dropdown-body ${dropdownState && "open"}`}>
                

                <h5>Features</h5>
                {props.item.features.map((currentFeature, featureIndex)=>{
                    return(
                        <li key={featureIndex}>{currentFeature}</li>
                    )
                    
                })}

                <h5>Potential Issues</h5>
                {props.item.issues.map((currentIssue, issueIndex)=>{
                    return(
                        <li key={issueIndex}>{currentIssue}</li>
                    )
                    
                })}

                <h5>Costs</h5>
                <p>{props.item.costs}</p>

                
                {props.item.links?

                        <div style={{display : "flex", flexDirection : "column", marginBottom : "2em"}}>
                            <h5>Related Articles</h5>
                            <a href={props.item.links.link1.url} target="_blank" rel="noreferrer" style={{color : "white"}}>{props.item.links.link1.text}</a>
                            <a href={props.item.links.link2.url} target="_blank" rel="noreferrer" style={{color : "white"}}>{props.item.links.link2.text}</a>
                        </div>   
                    : 
                    
                        <p>none</p>
                    
                }
                
            </div>
            <div className="dropdown-btn" onClick={dropdownToggle}>
                {dropdownState? "Show Less" : "Read More" }
                <span><i className={`fa fa-chevron-down icon ${dropdownState && "open"}`}></i></span>
            </div>

        </>
    )
}

export default Dropdown