import { outcomes } from "./questions";
import React, { useEffect } from "react";

const WorkingCard = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h6 style={{ margin: "2em 0 0.6em 0", fontWeight: "bold" }}>
        You answered '{props.choice.selected}' to '{props.choice.question}'{" "}
      </h6>
      <p style={{ margin: "0 0 1em 0" }}>{props.choice.info}</p>

      <div className="working-card-individual">
        {props.choice.weight.map((currentWeight, currentWeightIndex) => {
          return currentWeight > 0 ? (
            <div
              key={currentWeightIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  minHeight: "4em",
                  paddingBlock: "1em",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                {outcomes[currentWeightIndex]}
              </div>
              <i className="fas fa-check-circle fa-2x"></i>
            </div>
          ) : currentWeight === 0 ? (
            <div
              key={currentWeightIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  minHeight: "4em",
                  paddingBlock: "1em",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                {outcomes[currentWeightIndex]}
              </div>
              <i className="fas fa-exclamation-circle fa-2x"></i>
            </div>
          ) : (
            <div
              key={currentWeightIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  minHeight: "4em",
                  paddingBlock: "1em",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                {outcomes[currentWeightIndex]}
              </div>
              <i className="fas fa-times-circle fa-2x"></i>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkingCard;
